<template>
  <div class="coupon_box">
    <h2>Cupones</h2>
    <div class="row">
      <div class="col-lg-6" v-for="(item,index) in data" :key="index">
        <div class="box">
          <div class="box1">
            <h5>€ {{item.amount}}</h5>
            <p>consumo desde {{item.minLimit}}€</p>
          </div>
          <div class="box2">
            <button>CONSEGUIR</button>
            <p>{{item.startTime}}-{{item.endTime}}</p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {getCoupon} from '@/api'
export default {
  data() {
    return {
      data:[
        
      ]
    };
  },
  methods: {
    getList(){
      getCoupon({}).then(res=>{
        this.data=res.data.data.list;
      })
    }
  },
  computed: {
    ...mapGetters(['carts', 'token']),
  },
  created(){
    if(this.token){
      this.getList()
    }
  }
};
</script>
<style lang="less" >
.coupon_box {
  .row {
    margin: 50px 0;
  }
  .col-lg-6 {
    width: 100%;
    margin-bottom: 10px;
    .box {
      width: 80%;
      // height: 100px;
      display: flex;
      padding: 0;
      p {
        font-size: 14px;
      }
      .box1 {
        width: 50%;
        background-color: #fed100;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        justify-content: center;
        border-right: 2px dotted #000;
        h5 {
          margin: 0;
          font-size: 24px;
          width: 100%;
        }
        p{
          margin: 0;
        }
      }
      .box2 {
        width: 50%;
        // background-color: rgb(83, 151, 214);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        justify-content: center;

        border: 1px solid #f2f2f2;

        button {
          width: 100px;
          height: 33px;
          border-radius: 30px;
          border: 1px solid #fed100;
          font-size: 12px;
        }
        p {
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
